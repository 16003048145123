import {
  Grid,
  Card,
  Typography,
  CardContent,
  TextField,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox, 
  FormControlLabel
} from "@mui/material";

import { useState, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";

export default function SymptomsForm({ usuario }) {

  const [sintoma, SetSintoma] = useState({
    name: "",
    sym_id: "",
    hpo_id: "",
    synonymous: "",
    state: ""
  });

  const [linkreferencia, SetLinkReferencia] = useState("");
  const [important, SetImportant] = useState(false);
  const [frequency, SetFrequency] = useState('');

  const [cargando, SetCargando] = useState(false);
  const [editando, SetEditando] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [tieneidiomas, SetTieneIdiomas] = useState(false);

  const navigate = useNavigate();
  const params = useParams();

  const [idiomas, SetIdiomas] = useState([
    {
      sym_id: params.id ? params.id : sintoma.sym_id,
      language: "ES",
      value: "Ingrese traducción",
      state: "pending",
      username: usuario.username
    },
    {
      sym_id: params.id ? params.id : sintoma.sym_id,
      language: "EN",
      value: "Ingrese traducción",
      state: "pending",
      username: usuario.username
    },
    {
      sym_id: params.id ? params.id : sintoma.sym_id,
      language: "PO",
      value: "Ingrese traducción",
      state: "pending",
      username: usuario.username
    }
  ]);

  const camposIdiomaCompletados = () => {
    return idiomas.every(idioma => idioma.value !== "Ingrese traducción");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log('submit')
    //console.log(sintoma)

    SetCargando(true);

    if (editando) {
      console.log("Editado");
      console.log(sintoma);

      //HAGO EL POST Y RECIBO LA RESPUESTA EN RES PARA ACTUALIZAR UNA SINTOMA
      try {
        const res = await fetch(
          process.env.REACT_APP_API_URL + `/symptomsUpdate?id=${params.id}`,
          {
            method: "POST",
            body: JSON.stringify(sintoma),
            mode: "cors",
            headers: { "Content-Type": "application/json" },
          }
        );
        const data = await res.json();
        console.log(data);
      } catch (error) {

        console.log(error)

      }
    } else {
      //HAGO EL POST Y RECIBO LA RESPUESTA EN RES PARA CREAR UN SINTOMA
      console.log(sintoma)
      console.log("intento crear el sintoma...")

      try {
        const res = await fetch(process.env.REACT_APP_API_URL + `/symptoms?username=${usuario.username}`, {
          method: "POST",
          body: JSON.stringify(sintoma),
          mode: "cors",
          headers: { "Content-Type": "application/json" },
        });

        // OBTENGO LOS DATOS REALES DE LA RESPUESTA EL POST ANTERIOR
        //const data = await res.json();
        //console.log(data);
      } catch (error) {

        console.log(error)

      }

    }

    // creo registro en la tabla de patologias si es crear DESDE patologia
    if (params.pat) {
      console.log("intento crear la relacion con la patologia...")
      //AGREGA la id del sintoma seleccionada en el back end
      try {
        const resp = await fetch(
          process.env.REACT_APP_API_URL +
          `/pat_symAdd?pat_id=${params.pat}&sym_id=${sintoma.sym_id}&username=${usuario.username}&state=pending&link=${linkreferencia}&important=${important}&frequency=${frequency}`,
          {
            method: "POST",
            mode: "cors"
          }
        );

        //devuelve status = 2o4 si sale bien no un json
        //const data = await resp.json();
        //console.log(data);
      } catch (error) {

        console.log(error)

      }

    }


    //SI NO TENIA IDIOMAS LOS CREO AHORA!

    if (!tieneidiomas) {
      console.log("no tiene idiomas, hay que crearlos");

      for (const idioma of idiomas) {

        try {
          const res = await fetch(
            `${process.env.REACT_APP_API_URL}/symptomstranslationscreateone?username=${usuario.username}`,
            {
              method: "POST",
              body: JSON.stringify(idioma),
              mode: "cors",
              headers: { "Content-Type": "application/json" },
            }
          );
          const data = await res.json();
          console.log(data); // Puedes manejar la respuesta aquí
        } catch (error) {

          console.log(error)

        }

      }
    }


    //TERMINO EL PROCESO
    SetCargando(false);

    if (params.pat) {
      navigate(`/pathologies_symptoms/${params.pat}/${params.name}`);
    } else {
      //LO ENVIO AL INICIO
      navigate("/home/");
    }
  };

  const handleOnChange = (e) => {
    //  console.log(e.target.name, e.target.value)
    SetSintoma({ ...sintoma, [e.target.name]: e.target.value });
  };

  const handleOnChangelinkreferencia = (e) => {
    //  console.log(e.target.name, e.target.value)
    SetLinkReferencia(e.target.value);
    console.log(linkreferencia)
  };

  const handleOnChangeimportant = (e) => {
    const { name, value, type, checked } = e.target;
    console.log(e.target.checked)
    SetImportant(e.target.checked);
  };

  const handleOnChangefrequency = (e) => {
    //  console.log(e.target.name, e.target.value)
    console.log(e.target.value)
    SetFrequency(e.target.value);
   
  };

  const handleSubmitIdioma = async (idioma) => {
    //  idioma.preventDefault();

    console.log("Actualizando idioma...")
    console.log(idioma)

    const confirmed = window.confirm("¿Estás seguro de que deseas actualizar el idioma de este síntoma?");
    if (!confirmed) {
      return; // Si el usuario cancela, no ejecuta el delete
    }

    try {
      const res = await fetch(process.env.REACT_APP_API_URL + `/symptomstranslationsupdateone?username=${usuario.username}`, {
        method: "POST",
        body: JSON.stringify(idioma),
        mode: "cors",
        headers: { "Content-Type": "application/json" },
      });
    } catch (error) {

      console.log(error)

    }

  }

  const handleOnChangeidiomas = (e, index) => {
    const { name, value } = e.target;

    // Crear una copia de `idiomas` y actualizar el idioma específico
    const newIdiomas = [...idiomas];
    newIdiomas[index] = {
      ...newIdiomas[index],
      [name]: value
    };

    // Actualizar el estado con la copia modificada
    SetIdiomas(newIdiomas);
  };

  //// AGREGO CODIGO PARA CUANDO VENGO A EDITAR USANDO EL MISMO FORM DE CREAR

  const cargarSintoma = async (id) => {
    const res = await fetch(process.env.REACT_APP_API_URL + `/symptomsOne?id=${id}`);
    const data = await res.json();
    //data= data[0];
    //console.log(data[0])

    // Asignar valor predeterminado a `state` si viene `null` de la base
    const hpo_idDefault = data[0].hpo_id === null ? '' : data[0].hpo_id;
    const synonymousDefault = data[0].synonymous === null ? '' : data[0].synonymous;
    const estadoDefault = data[0].state === null ? 'verified' : data[0].state;
    console.log(data)

    //console.log(data[0].name)
    SetSintoma({
      name: data[0].name,
      sym_id: data[0].sym_id,
      hpo_id: hpo_idDefault,
      synonymous: synonymousDefault,
      state: estadoDefault
    });
    //console.log(sintoma)
    //console.log(sintoma.name)
    //SetSintoma({ sym_id: numeroultimo, state: "pending" });

    SetEditando(true);
  };

  const zeroPad = (num, places) => String(num).padStart(places, "0");

  const cargarUltimoSintoma = async () => {
    try {
      const res = await fetch(
        process.env.REACT_APP_API_URL + `/symptomsLast`
      );
      const data = await res.json();

      console.log(data[0].max);
      let dato = data[0].max.split("_");
      console.log(dato[1]);
      let numeroultimo = parseInt(dato[1]) + 1;
      console.log(numeroultimo);
      numeroultimo = zeroPad(numeroultimo, 6);
      numeroultimo = "SYM_" + numeroultimo;
      TextField.sym_id = numeroultimo;

      SetSintoma({ sym_id: numeroultimo, state: "pending" });

      SetIdiomas([
        {
          sym_id: numeroultimo,
          language: "ES",
          value: "Ingrese traducción",
          state: "pending",
          username: usuario.username
        },
        {
          sym_id: numeroultimo,
          language: "EN",
          value: "Ingrese traducción",
          state: "pending",
          username: usuario.username
        },
        {
          sym_id: numeroultimo,
          language: "PO",
          value: "Ingrese traducción",
          state: "pending",
          username: usuario.username
        }
      ])
      console.log(idiomas)

    } catch (error) {
      console.log(error);
    }
  };


  const obtenerUltimoCodigoRDC = async () => {
    try {
      const res = await fetch(process.env.REACT_APP_API_URL + `/symptomsLastRDC`);
      const data = await res.json();

      console.log(data[0].max);
      let dato = data[0].max.split("_");
      console.log(dato[1]);
      let numeroultimo = parseInt(dato[1]) + 1;
      console.log(numeroultimo);
      numeroultimo = zeroPad(numeroultimo, 6);
      numeroultimo = "RDC_" + numeroultimo;
      TextField.sym_id = numeroultimo;


      SetSintoma((prev) => ({ ...prev, hpo_id: numeroultimo }));
    } catch (error) {
      console.error('Error al obtener el código:', error);
    }
  };

  const handleCheckboxChange = (e) => {
    setCheckboxChecked(e.target.checked);
    if (e.target.checked) {
      obtenerUltimoCodigoRDC(); // Llama a la función para obtener el código
    } else {
      SetSintoma((prev) => ({ ...prev, hpo_id: '' })); // Limpia el campo si no está chequeado
    }
  };

  //PIDO LOS DATOS AL BACKEND
  const loadIdiomas = async (id) => {

    try {
      const response = await fetch(process.env.REACT_APP_API_URL + `/symptomstranslationsgetone?sym_id=${id}`)
      const data = await response.json()
      //console.log(data)

      // Solo actualiza el estado si `data` no está vacío
      if (data && data.length > 0) {
        SetIdiomas(data);
        console.log("Idiomas cargados:", data);
        SetTieneIdiomas(true);
      } else {
        console.log("La consulta de idiomas está vacía, no se actualiza el estado.");

      }

      console.log(data)
      console.log(idiomas)
    } catch (error) {

      console.log(error)

    }

  }


  useEffect(() => {
    if (usuario.type === '') {
      navigate("/");
    }
    //si viene desde una patologia para crear el sintoma tiene este dato que usa despues
    if (params.pat) {
      console.log(params.pat);
    }

    //si viene solamente a editar un sintoma
    if (params.id) {
      console.log("viene con un dato id sintoma a editar");
      console.log(params.id);
      cargarSintoma(params.id);
      loadIdiomas(params.id);
      //  console.log(user.type)
    } else {
      //si viene a crear un nuevo sintoma
      cargarUltimoSintoma();
    }
  }, [params.id]);

  return (
    <Grid
      container
      direction={"column"}
      alignContent={"center"}
      justifyContent={"center"}
    >
      <Grid item xs={3}>
        <Card
          sx={{ mt: 5, width: 550 }}
          style={{
            backgroundColor: "#bdbdbd",
            padding: "1 rem",
          }}
        >
          <Typography variant="h6" textAlign="center">
            {editando ? "Editar Síntoma" : "Crear Nuevo Síntoma"}
          </Typography>
          <CardContent>
            <form onSubmit={handleSubmit}>
              {params.pat ? (
                <TextField
                  variant="standard"
                  label="Patología donde se incorpora el síntoma"
                  name="patologia"
                  value={params.pat || ''}
                  disabled={true}
                  onChange={handleOnChange}
                  sx={{ display: "flex", margin: ".5rem 0" }}
                  inputprops={{ style: { color: "white" } }}
                  inputlabelprops={{ style: { color: "white" } }}
                />

              ) : (
                ''
              )}

              {params.pat ? (
                <TextField
                  variant="filled"
                  label="Link de Referencia de la relación"
                  name="link"
                  value={linkreferencia || ''}
                  onChange={handleOnChangelinkreferencia}
                  sx={{ display: "flex", margin: ".5rem 0" }}
                  inputprops={{ style: { color: "white" } }}
                  inputlabelprops={{ style: { color: "white" } }}
                />
              ) : (
                ''
              )}


              {params.pat ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      name="important"
                      checked={important || false}
                      onChange={handleOnChangeimportant}
                    />
                  }
                  label="Importante"
                />
              ) : (
                ''
              )}

              {params.pat ? (


                <Select
                  variant="filled"
                  name="frequency"
                  label="Frecuencia"
                  value={frequency  || ''}
                  onChange={handleOnChangefrequency}
                  displayEmpty
                  sx={{ display: 'block', margin: '.5rem 0', width: '100%' }}
                >
                  <MenuItem value="">Seleccione la frecuencia:</MenuItem>
                  <MenuItem value="Muy poco frecuente">Muy poco frecuente</MenuItem>
                  <MenuItem value="Poco frecuente">Poco frecuente</MenuItem>
                  <MenuItem value="Frecuente">Frecuente</MenuItem>
                  <MenuItem value="Muy frecuente">Muy frecuente</MenuItem>
                </Select>
              ) : (
                ''
              )}

              <br></br>

              <TextField
                variant="filled"
                label="Nombre del síntoma"
                name="name"
                value={sintoma.name || ''}
                onChange={handleOnChange}
                sx={{ display: "flex", margin: ".5rem 0" }}
                inputprops={{ style: { color: "white" } }}
                inputlabelprops={{ style: { color: "white" } }}
              />
              <TextField
                variant="standard"
                label="Código síntoma (generado por el sistema)"
                name="sym_id"
                value={sintoma.sym_id || ''}
                disabled={true}
                onChange={handleOnChange}
                sx={{ display: "block", margin: ".5rem 0" }}
                inputprops={{ style: { color: "white" } }}
                inputlabelprops={{ style: { color: "white" } }}
              />
              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={checkboxChecked}
                    onChange={handleCheckboxChange}
                  />
                  Completar código RDC automáticamente
                </label>
              </div>

              <TextField
                variant="filled"
                label="Código HPO/RDC"
                name="hpo_id"
                value={sintoma.hpo_id || ''}
                onChange={handleOnChange}
                sx={{ display: "block", margin: ".5rem 0" }}
                inputprops={{ style: { color: "white" } }}
                inputlabelprops={{ style: { color: "white" } }}
              />
              <TextField
                variant="filled"
                label="Sinónimo"
                name="synonymous"
                value={sintoma.synonymous || ''}
                onChange={handleOnChange}
                sx={{ display: "flex", margin: ".5rem 0" }}
                inputprops={{ style: { color: "white" } }}
                inputlabelprops={{ style: { color: "white" } }}
              />

              <FormControl fullWidth>
                <InputLabel id="Estado">Estado</InputLabel>
                <Select
                  //labelId="demo-simple-select-label"
                  //id="demo-simple-select"
                  value={sintoma.state || ''}
                  label="Estado"
                  name="state"
                  //  disabled={true}
                  onChange={handleOnChange}
                  inputprops={{ style: { color: "white" } }}
                  inputlabelprops={{ style: { color: "white" } }}
                >
                  <MenuItem
                    inputprops={{ style: { color: "white" } }}
                    inputlabelprops={{ style: { color: "white" } }}
                    key="pending"
                    value="pending"
                  >
                    pending
                  </MenuItem>

                  {usuario.type === 'admin' ? (
                    <MenuItem
                      inputprops={{ style: { color: "white" } }}
                      inputlabelprops={{ style: { color: "white" } }}
                      key="preverified"
                      value="preverified"
                    >
                      preverified
                    </MenuItem>
                  ) :
                    null
                  }

                  {usuario.type === 'admin' ? (
                    <MenuItem
                      inputprops={{ style: { color: "white" } }}
                      inputlabelprops={{ style: { color: "white" } }}
                      key="verified"
                      value="verified"
                    >
                      verified
                    </MenuItem>
                  ) :
                    null
                  }

                  {usuario.type === 'admin' ? (
                    <MenuItem
                      inputprops={{ style: { color: "white" } }}
                      inputlabelprops={{ style: { color: "white" } }}
                      key="bot_verified"
                      value="bot_verified"
                    >
                      bot_verified
                    </MenuItem>
                  ) :
                    null
                  }

                </Select>
              </FormControl>

              <br></br>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{ display: "block", margin: ".5rem auto" }}
                disabled={!sintoma.name || !sintoma.sym_id || cargando || !camposIdiomaCompletados()}
              >
                {cargando ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "Guardar"
                )}
              </Button>

              {!camposIdiomaCompletados() && (
                <Typography variant="body2" color="error" textAlign="center" sx={{ marginTop: ".5rem" }}>
                  Por favor, complete todas las traducciones antes de guardar.
                </Typography>
              )}

            </form>

            <br></br>
            <Typography variant="h6" textAlign="center">
              Idiomas:
            </Typography>
            <br></br>

            {

              (idiomas.length !== 0) ?


                idiomas.map((idioma, index) => (
                  <Card
                    style={
                      {
                        marginBottom: '.3rem',
                        backgroundColor: '#bdbdbd'
                      }
                    }
                    key={idioma.language}
                  >
                    <CardContent style={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}>
                      <div style={{ color: 'white' }}>

                        <Grid container spacing={3}>
                          <Grid item xs={3}> {/* Ocupa menos espacio */}

                            <TextField
                              variant="filled"
                              label="Idioma"
                              name="language"
                              value={idioma.language}
                              disabled={true}
                              onChange={(e) => handleOnChangeidiomas(e, index)}
                              sx={{ display: "flex", margin: ".5rem 0" }}
                              inputprops={{ style: { color: "white" } }}
                              inputlabelprops={{ style: { color: "white" } }}
                            />
                          </Grid>

                          <Grid item xs={9}> {/* Ocupa más espacio */}
                            <TextField
                              variant="filled"
                              label="Traducción"
                              name="value"
                              value={idioma.value || ''}
                              onChange={(e) => handleOnChangeidiomas(e, index)}
                              sx={{ display: "flex", margin: ".5rem 0", width: "100%" }}
                              inputprops={{ style: { color: "white" } }}
                              inputlabelprops={{ style: { color: "white" } }}
                            />
                          </Grid></Grid>

                        {(tieneidiomas) ?
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={() => handleSubmitIdioma(idioma)}
                            sx={{ display: "block", margin: ".5rem auto" }}
                            disabled={!idioma.language || !idioma.value}
                          >
                            {cargando ? (
                              <CircularProgress color="inherit" size={24} />
                            ) : (
                              "Actualizar idioma"
                            )}
                          </Button>
                          :
                          ''
                        }
                      </div>
                    </CardContent>
                  </Card >
                )

                )

                :
                <Grid container spacing={3}>
                  <Grid item xs={1}>

                  </Grid>

                  <Grid item xs={10} >
                    <h4>No se han cargado idiomas para este síntoma.</h4>


                  </Grid>

                  <Grid item xs={1} >

                  </Grid>
                </Grid>

            }





          </CardContent>
        </Card>
      </Grid>
    </Grid >
  );
}
