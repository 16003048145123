import {
    Grid, Card, Typography, CardContent, TextField, Button, CircularProgress,
    FormControl, InputLabel, Select, MenuItem
} from '@mui/material';

import { useState, useEffect, useMemo } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

export default function CategoriesSubSystemForm({ usuario }) {

    const [categoria, setCategoria] = useState({
        cat_id: '',
        name: '',
        type: ''
    });

    const [cargando, setCargando] = useState(false);
    const [editando, setEditando] = useState(false);
    const [tiposDeCategoria, setTiposDeCategoria] = useState([]);
    const [tieneidiomas, SetTieneIdiomas] = useState(false);
    const [categoriasSystemListBox, setCategoriasSystemListBox] = useState([]);

    const [datosSeleccionados, setDatosSeleccionados] = useState({
        categoria: '',
        cat_id_1: '',
        name_1: '',
        type_1: ''
    });

    // Estado para el Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 'error', 'warning', 'info', 'success'
    });

    // Función para cerrar el Snackbar
    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    const navigate = useNavigate();
    const params = useParams();

    const [idiomas, SetIdiomas] = useState([
        {
            cat_id: params.id ? params.id : categoria.cat_id,
            language: "ES",
            value: "Ingrese traducción",
            state: "pending",
            username: usuario.username
        },
        {
            cat_id: params.id ? params.id : categoria.cat_id,
            language: "EN",
            value: "Ingrese traducción",
            state: "pending",
            username: usuario.username
        },
        {
            cat_id: params.id ? params.id : categoria.cat_id,
            language: "PO",
            value: "Ingrese traducción",
            state: "pending",
            username: usuario.username
        }
    ]);

    const camposIdiomaCompletados = () => {
        return idiomas.every(idioma => idioma.value !== "Ingrese traducción");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setCargando(true);

        if (editando) {

            const confirmed = window.confirm(`¿Estás seguro de que deseas modificar la subcategoría: ${categoria.name} de la categoría ${datosSeleccionados.name_1}?`);
            if (!confirmed) {
                setCargando(false);
                return;
            }

            try {
                const res = await fetch(`${process.env.REACT_APP_API_URL}/categoriesUpdate?id=${params.id}`, {
                    method: "POST",
                    body: JSON.stringify(categoria),
                    mode: "cors",
                    headers: { "Content-Type": "application/json" }
                });

                if (res.ok) {
                    const data = await res.json();
                    console.log(data);
                    setSnackbar({
                        open: true,
                        message: 'Categoría actualizada exitosamente.',
                        severity: 'success',
                    });
                } else {
                    const errorText = await res.text();
                    console.error("Error al actualizar la categoría:", res.statusText, errorText);
                    setSnackbar({
                        open: true,
                        message: 'Error al actualizar la categoría.',
                        severity: 'error',
                    });
                }

            } catch (error) {
                console.error('Error:', error);
                setSnackbar({
                    open: true,
                    message: 'Error inesperado al actualizar la categoría.',
                    severity: 'error',
                });
            }

        } else {

            const confirmed2 = window.confirm(`¿Estás seguro de que deseas incorporar la subcategoría: ${categoria.name} al sistema?`);
            if (!confirmed2) {
                setCargando(false);
                return;
            }

            try {
                const res = await fetch(`${process.env.REACT_APP_API_URL}/categoriesAdd?username=${usuario.username}&state=pending`, {
                    method: "POST",
                    body: JSON.stringify(categoria),
                    mode: "cors",
                    headers: { "Content-Type": "application/json" }
                });

                if (!res.ok) {
                    throw new Error(`HTTP error! status: ${res.status}`);
                }

                const data = await res.json();
                console.log('Success:', data);
                setSnackbar({
                    open: true,
                    message: 'Categoría creada exitosamente.',
                    severity: 'success',
                });

            } catch (error) {
                console.error('Error:', error);
                setSnackbar({
                    open: true,
                    message: 'Error al crear la categoría.',
                    severity: 'error',
                });
                setCargando(false);
                return; // Salir si hay error
            }

            const confirmed3 = window.confirm(`¿Estás seguro de que deseas incorporar la subcategoría: ${categoria.name} a la categoría ${datosSeleccionados.name_1}?`);
            if (!confirmed3) {
                setCargando(false);
                return;
            }

            try {
                const resp = await fetch(`${process.env.REACT_APP_API_URL}/cat_catAdd?cat_id_1=${datosSeleccionados.cat_id_1}&cat_id_2=${categoria.cat_id}&username=${usuario.username}&state=pending`, {
                    method: "POST",
                    mode: "cors"
                });

                if (resp.ok) {
                    console.log('Relación categoría padre-hija creada exitosamente.');
                    setSnackbar({
                        open: true,
                        message: 'Relación categoría padre-hija creada exitosamente.',
                        severity: 'success',
                    });
                } else {
                    const errorText = await resp.text();
                    console.error("Error al crear la relación categoría padre-hija:", resp.statusText, errorText);
                    setSnackbar({
                        open: true,
                        message: 'Error al crear la relación categoría padre-hija.',
                        severity: 'error',
                    });
                }

            } catch (error) {
                console.log(error);
                setSnackbar({
                    open: true,
                    message: 'Error inesperado al crear la relación categoría padre-hija.',
                    severity: 'error',
                });
            }

        }


        //SI NO TENIA IDIOMAS LOS CREO AHORA!

        if (!tieneidiomas) {
            console.log("no tiene idiomas, hay que crearlos");

            for (const idioma of idiomas) {

                try {
                    const res = await fetch(
                        `${process.env.REACT_APP_API_URL}/categoriestranslationscreateone?username=${usuario.username}`,
                        {
                            method: "POST",
                            body: JSON.stringify(idioma),
                            mode: "cors",
                            headers: { "Content-Type": "application/json" },
                        }
                    );
                    const data = await res.json();
                    console.log(data); // Puedes manejar la respuesta aquí

                } catch (error) {

                    console.log(error)

                }

            }
        }


        // Termino el proceso
        setCargando(false);

        // Navego al inicio
        navigate('/home/');
    }

    const handleOnChange = e => {
        setCategoria({ ...categoria, [e.target.name]: e.target.value });
    }


    const handleSubmitIdioma = async (idioma) => {
        //  idioma.preventDefault();

        console.log("Actualizando idioma...")
        console.log(idioma)

        const confirmed = window.confirm("¿Estás seguro de que deseas actualizar el idioma de esta categoria?");
        if (!confirmed) {
            return; // Si el usuario cancela, no ejecuta el delete
        }

        try {
            const res = await fetch(process.env.REACT_APP_API_URL + `/categoriestranslationsupdateone?username=${usuario.username}`, {
                method: "POST",
                body: JSON.stringify(idioma),
                mode: "cors",
                headers: { "Content-Type": "application/json" },
            });

        } catch (error) {

            console.log(error)

        }

    }

    const handleOnChangeidiomas = (e, index) => {
        const { name, value } = e.target;

        // Crear una copia de `idiomas` y actualizar el idioma específico
        const newIdiomas = [...idiomas];
        newIdiomas[index] = {
            ...newIdiomas[index],
            [name]: value
        };

        // Actualizar el estado con la copia modificada
        SetIdiomas(newIdiomas);
    };

    // Cargar categoría para editar
    const cargarCategoria = async (id) => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/categoriesOne?id=${id}`);
            if (!res.ok) {
                throw new Error(`Error fetching category: ${res.statusText}`);
            }
            const data = await res.json();
            console.log(data[0]);

            setCategoria({
                cat_id: data[0].cat_id,
                name: data[0].name,
                type: data[0].type
            });

            setEditando(true);
        } catch (error) {
            console.error('Error al cargar la categoría:', error);
            setSnackbar({
                open: true,
                message: 'Error al cargar la categoría.',
                severity: 'error',
            });
        }
    }

    const buscarTiposDeCategoria = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/categoriesTypes/`);
            if (!res.ok) {
                throw new Error(`Error fetching types: ${res.statusText}`);
            }
            const data = await res.json();
            console.log(data);
            setTiposDeCategoria(data);
        } catch (error) {
            console.log(error);
            setSnackbar({
                open: true,
                message: 'Error al cargar los tipos de categoría.',
                severity: 'error',
            });
        }
    }

    const zeroPad = (num, places) => String(num).padStart(places, '0');

    const cargarUltimaCategoria = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/categoriesLast/`);
            if (!res.ok) {
                throw new Error(`Error fetching last category: ${res.statusText}`);
            }
            const data = await res.json();

            console.log(data[0].max);
            let dato = data[0].max.split("_");
            console.log(dato[1]);
            let numeroultimo = parseInt(dato[1]) + 1;
            console.log(numeroultimo);
            numeroultimo = zeroPad(numeroultimo, 6);
            numeroultimo = 'CAT_' + numeroultimo;
            console.log(numeroultimo);

            setCategoria(prev => ({
                ...prev,
                cat_id: numeroultimo,
                type: "subsystem"
            }));

            SetIdiomas([
                {
                    cat_id: numeroultimo,
                    language: "ES",
                    value: "Ingrese traducción",
                    state: "pending",
                    username: usuario.username
                },
                {
                    cat_id: numeroultimo,
                    language: "EN",
                    value: "Ingrese traducción",
                    state: "pending",
                    username: usuario.username
                },
                {
                    cat_id: numeroultimo,
                    language: "PO",
                    value: "Ingrese traducción",
                    state: "pending",
                    username: usuario.username
                }
            ])
            console.log(idiomas)


        } catch (error) {
            console.log(error);
            setSnackbar({
                open: true,
                message: 'Error al cargar la última categoría.',
                severity: 'error',
            });
        }
    }

    // Buscar categorías del sistema
    const buscarCategoriasSystem = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/categories`);
            if (!res.ok) {
                throw new Error(`Error fetching categories: ${res.statusText}`);
            }
            const data = await res.json();
            setCategoriasSystemListBox(data);
        } catch (error) {
            console.log("Error en buscarCategoriasSystem:", error);
            setSnackbar({
                open: true,
                message: 'Error al cargar las categorías del sistema.',
                severity: 'error',
            });
        }
        console.log("Después de buscarCategoriasSystem:", datosSeleccionados);
    };

    // Maneja el cambio de categoría
    const handleOnChangecat = (e) => {
        const selectedCatId = e.target.value;
        const selectedCategory = categoriasSystemListBox.find(cat => cat.cat_id === selectedCatId);

        if (selectedCategory) {
            setDatosSeleccionados(prev => ({
                ...prev,
                categoria: selectedCatId,
                cat_id_1: selectedCategory.cat_id,
                name_1: selectedCategory.name,
                type_1: selectedCategory.type,
            }));
        }
        console.log(datosSeleccionados); // Ten en cuenta que esto puede no mostrar el estado actualizado inmediatamente
    };

    //PIDO LOS DATOS AL BACKEND
    const loadIdiomas = async (id) => {

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + `/categoriestranslationsgetone?cat_id=${id}`)
            const data = await response.json()
            //console.log(data)

            // Solo actualiza el estado si `data` no está vacío
            if (data && data.length > 0) {
                SetIdiomas(data);
                console.log("Idiomas cargados:", data);
                SetTieneIdiomas(true);
            } else {
                console.log("La consulta de idiomas está vacía, no se actualiza el estado.");

            }

            console.log(data)
            console.log(idiomas)
        } catch (error) {

            console.log(error)

        }

    }

    useEffect(() => {
        if (usuario.type === '') {
            navigate("/");
        }
        buscarTiposDeCategoria();
        // Si viene con algún id..
        if (params.id) {
            cargarCategoria(params.id);
            loadIdiomas(params.id)
        }
        // Voy a crear uno nuevo, cargo la última para poder crear una nueva
        else {
            cargarUltimaCategoria();
            buscarCategoriasSystem();
        }
    }, [params.id, usuario.type, navigate]);

    return (

        <Grid container direction={'column'} alignContent={'center'} justifyContent={'center'} >
            <Grid item xs={8}>
                <Card
                    sx={{ mt: 5, width: 550 }}
                    style={{
                        backgroundColor: '#bdbdbd',
                        padding: '1rem' // Corregir el espacio
                    }}
                >
                    <Typography variant='h6' textAlign='center' >
                        {editando ? "Editar Categoría Subsystem" : "Crear Nueva Categoría Subsystem"}
                    </Typography>
                    <CardContent >
                        <form onSubmit={handleSubmit}>
                            {/* Selección de Categoría */}
                            <FormControl
                                fullWidth
                                sx={{ marginBottom: '0.9rem' }}
                            >
                                <InputLabel id="Tipo">Seleccione categoría</InputLabel>
                                <Select
                                    labelId="Tipo"
                                    label='Seleccione categoría'
                                    name='categoria'
                                    value={datosSeleccionados.categoria || ''}
                                    onChange={handleOnChangecat}
                                    inputprops={{ sx: { color: 'white' } }}
                                    inputlabelprops={{ sx: { color: 'white' } }}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                backgroundColor: '#333', // Color de fondo del listbox
                                                color: '#fff', // Color del texto del listbox
                                            },
                                        },
                                    }}
                                >
                                    {categoriasSystemListBox.map(cat => (
                                        <MenuItem
                                            key={cat.cat_id}
                                            value={cat.cat_id || ''}
                                            sx={{ color: 'white' }} // Color del texto de cada ítem
                                        >
                                            {cat.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {/* Nombre de la Subcategoría */}
                            <TextField
                                variant='filled'
                                label='Nombre de la Subcategoría'
                                name='name'
                                value={categoria.name || ''}
                                onChange={handleOnChange}
                                sx={{ display: 'flex', margin: '.5rem 0' }}
                                inputprops={{ style: { color: 'white' } }}
                                inputlabelprops={{ style: { color: 'white' } }}
                            />

                            {/* Código Categoría */}
                            <TextField
                                variant='standard'
                                label='Código Categoría (generado por el sistema)'
                                name='cat_id'
                                disabled={true}
                                value={categoria.cat_id || ''}
                                onChange={handleOnChange}
                                sx={{ display: 'block', margin: '.5rem 0' }}
                                inputprops={{ style: { color: 'white' } }}
                                inputlabelprops={{ style: { color: 'white' } }}
                            />

                            {/* Selección de Tipo */}
                            {
                                (tiposDeCategoria.length !== 0) ?
                                    <FormControl fullWidth>
                                        <InputLabel id="Tipo">Tipo</InputLabel>
                                        <Select
                                            label="Tipo"
                                            name='type'
                                            value={categoria.type || ''}
                                            onChange={handleOnChange}
                                            inputprops={{ style: { color: 'white' } }}
                                            inputlabelprops={{ style: { color: 'white' } }}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        backgroundColor: '#333', // Color de fondo del listbox
                                                        color: '#fff', // Color del texto del listbox
                                                    },
                                                },
                                            }}
                                        >
                                            {
                                                tiposDeCategoria.map(tipo => (
                                                    <MenuItem
                                                        key={tipo.type}
                                                        value={tipo.type || ''}
                                                        sx={{ color: 'white' }} // Usar sx para estilos
                                                    >
                                                        {tipo.type}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                    :
                                    "Cargando..."
                            }

                            {/* Botón de Guardar */}
                            <Button
                                variant='contained'
                                color='primary'
                                type='submit'
                                sx={{ display: "block", margin: ".5rem auto" }}
                                disabled={!categoria.cat_id || !categoria.name || !categoria.type || cargando || !camposIdiomaCompletados()}
                            >
                                {cargando ? <CircularProgress color='inherit' size={24} /> : 'Guardar'}
                            </Button>


                            {!camposIdiomaCompletados() && (
                                <Typography variant="body2" color="error" textAlign="center" sx={{ marginTop: ".5rem" }}>
                                    Por favor, complete todas las traducciones antes de guardar.
                                </Typography>
                            )}

                        </form>


                        <br></br>
                        <Typography variant="h6" textAlign="center">
                            Idiomas:
                        </Typography>
                        <br></br>

                        {

                            (idiomas.length !== 0) ?


                                idiomas.map((idioma, index) => (
                                    <Card
                                        style={
                                            {
                                                marginBottom: '.3rem',
                                                backgroundColor: '#bdbdbd'
                                            }
                                        }
                                        key={idioma.language}
                                    >
                                        <CardContent style={{
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}>
                                            <div style={{ color: 'white' }}>

                                                <Grid container spacing={3}>
                                                    <Grid item xs={3}> {/* Ocupa menos espacio */}

                                                        <TextField
                                                            variant="filled"
                                                            label="Idioma"
                                                            name="language"
                                                            value={idioma.language || ''}
                                                            disabled={true}
                                                            onChange={(e) => handleOnChangeidiomas(e, index)}
                                                            sx={{ display: "flex", margin: ".5rem 0" }}
                                                            inputprops={{ style: { color: "white" } }}
                                                            inputlabelprops={{ style: { color: "white" } }}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={9}> {/* Ocupa más espacio */}
                                                        <TextField
                                                            variant="filled"
                                                            label="Traducción"
                                                            name="value"
                                                            value={idioma.value || ''}
                                                            onChange={(e) => handleOnChangeidiomas(e, index)}
                                                            sx={{ display: "flex", margin: ".5rem 0", width: "100%" }}
                                                            inputprops={{ style: { color: "white" } }}
                                                            inputlabelprops={{ style: { color: "white" } }}
                                                        />
                                                    </Grid></Grid>

                                                {(tieneidiomas) ?
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                        onClick={() => handleSubmitIdioma(idioma)}
                                                        sx={{ display: "block", margin: ".5rem auto" }}
                                                        disabled={!idioma.language || !idioma.value}
                                                    >
                                                        {cargando ? (
                                                            <CircularProgress color="inherit" size={24} />
                                                        ) : (
                                                            "Actualizar idioma"
                                                        )}
                                                    </Button>
                                                    :
                                                    ''
                                                }
                                            </div>
                                        </CardContent>
                                    </Card >
                                )

                                )

                                :
                                <Grid container spacing={3}>
                                    <Grid item xs={1}>

                                    </Grid>

                                    <Grid item xs={10} >
                                        <h4>No se han cargado idiomas para este síntoma.</h4>


                                    </Grid>

                                    <Grid item xs={1} >

                                    </Grid>
                                </Grid>

                        }

                    </CardContent>
                </Card>
            </Grid>

            {/* Snackbar para notificaciones */}
            {snackbar.open && (
                <div
                    style={{
                        position: 'fixed',
                        bottom: '20px',
                        right: '20px',
                        backgroundColor:
                            snackbar.severity === 'success' ? 'green' :
                                snackbar.severity === 'error' ? 'red' :
                                    snackbar.severity === 'warning' ? 'orange' :
                                        'blue',
                        color: 'white',
                        padding: '10px',
                        borderRadius: '5px',
                        zIndex: 1000,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        minWidth: '200px'
                    }}
                >
                    <span>{snackbar.message}</span>
                    <Button onClick={handleCloseSnackbar} style={{ color: 'white' }}>X</Button>
                </div>
            )}
        </Grid>
    )
}
