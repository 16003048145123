import { useEffect, useState } from "react"
import { Button, Card, CardContent, Typography, Grid, Snackbar, Alert } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom';

export default function PathologiesFromOneSymptom({ usuario }) {

  const [patologias, SetPatologias] = useState([])
  const [patologiascount, SetPatologiasCount] = useState({
    'count': '0'
  });
  const navigate = useNavigate();
  const params = useParams();

  // Snackbar state
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  // Función para cerrar el Snackbar
  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };


  //PIDO LOS DATOS AL BACKEND
  const loadPathologiesFromOneSymptom = async () => {

    try {
      //console.log(process.env.REACT_APP_API_URL)
      const response = await fetch(process.env.REACT_APP_API_URL + `/pathologiesSearchbyonesymptom?sym_id_1=${params.sym_id}`)

      const data = await response.json()
      // console.log(data)
      SetPatologias(data)
      //console.log(patologias)
    } catch (error) {

      //console.log(error)
      console.log(error.message)
    }

  }

  //PIDO LOS DATOS AL BACKEND
  const loadPathologiesFromOneSymptomCount = async () => {

    try {

      const response = await fetch(process.env.REACT_APP_API_URL + `/pathologiesSearchbyonesymptomCount?sym_id_1=${params.sym_id}`)
      const data = await response.json()
      //console.log(data)
      SetPatologiasCount(data)
      //console.log(patologiascount)
    } catch (error) {

      //console.log(error)
      console.log(error.message)
    }

  }

  //recibe parametro para BORRAR la id seleccionada en el backend
  const handleDelete = async (pat_id, sym_id) => {
    //borra la id seleccionada en el frontend

    const confirmed = window.confirm("¿Estás seguro de que deseas eliminar la relación de este síntoma con esta patología?");

    if (!confirmed) {
      return; // Si el usuario cancela, no ejecuta el delete
    }

    SetPatologias(patologias.filter(patologia => patologia.pat_id !== pat_id))

    try {
      //borra la id seleccionada en el back end
      // Aca hay que borrar de la tabla patologia sintoma la relacion! 
      const resp = await fetch(process.env.REACT_APP_API_URL + `/pat_symDelete?pat_id=${pat_id}&sym_id=${sym_id}`,
        {
          method: "POST",
          mode: "cors"
        }
      )

      //devuelve status = 2o4 si sale bien no un json
      //const data = await resp.json();
      //console.log(data);
      //console.log(resp);

      setSnackbar({
        open: true,
        message: 'Relación patología-síntoma eliminada exitosamente.',
        severity: 'success',
      });

    } catch (error) {

      console.log(error)
      setSnackbar({
        open: true,
        message: 'Error al eliminar el síntoma.',
        severity: 'error',
      });
    }

  }


  useEffect(() => {
    if (usuario.type === '') {
      navigate("/");
    }
    loadPathologiesFromOneSymptomCount()
    loadPathologiesFromOneSymptom()
  }, [])


  return (
    <>
      <h2>Listado de Patologías del sistema para el síntoma seleccionado: {params.sym_id}</h2>

      <h3>Mostrando {(patologiascount.count !== '0') ? patologiascount[0].count : '0'} registros </h3>

      {

        (patologias.length !== 0) ?


          patologias.map(patologia => (
            <Card
              style={
                {
                  marginBottom: '.7rem',
                  backgroundColor: '#1e272e'
                }
              }
              key={patologia.pat_id}
            >
              <CardContent style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}>

                <Grid container spacing={2}>
                  <Grid item xs={6}>

                    <div style={{ color: 'white' }}>
                      <Typography variant='h6'>{patologia.name}</Typography>
                      <Typography>{'Pat ID: ' + patologia.pat_id}</Typography>
                      <Typography>{'Orpha: ' + patologia.orpha_id}</Typography>
                      <Typography>{'Omim: ' + patologia.omim_id}</Typography>

                    </div>

                  </Grid>
                  <Grid item xs={6}>

                    <div>
                      <Button
                        variant="contained"
                        color="inherit"
                        style={{
                          marginLeft: '.5rem',
                          marginBottom: '.5rem'
                        }}
                        onClick={() => navigate(`/pathologies/${patologia.pat_id}/edit`)}
                      >
                        Editar
                      </Button>
                      <Button
                        variant="contained"
                        color="inherit"
                        //onClick={() => console.log('Editando')}
                        onClick={() => navigate(`/pathologies_symptoms/${patologia.pat_id}/${patologia.name}`)}
                        style={{
                          marginLeft: '.5rem',
                          marginBottom: '.5rem'
                        }}
                      >
                        síntomas asociados
                      </Button>

                      {usuario.type === 'admin' ?
                        <Button
                          variant="contained"
                          color="warning"
                          //onClick={() => console.log('Borrando')}
                          onClick={() => handleDelete(patologia.pat_id, params.sym_id)}
                          style={{
                            marginLeft: '.5rem',
                            marginBottom: '.5rem'
                          }}
                        >
                          Borrar el sintoma de esta patologia
                        </Button>
                        :
                        null
                      }
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card >
          )
          )

          :
          <Grid container spacing={3}>
            <Grid item xs={1} >

            </Grid>

            <Grid item xs={10} textAlign={"center"}>
              <h4>No hay resultados para la búsqueda.</h4>
            </Grid>

            <Grid item xs={1} >

            </Grid>
          </Grid>

      }

      {/* Snackbar para notificaciones */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

    </>
  )
}
