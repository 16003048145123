import { useEffect, useState } from "react"
import { Button, Card, CardContent, Typography, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom';

export default function CategoriesList({ usuario }) {

  const [usuarios, SetUsuarios] = useState([])
  const navigate = useNavigate();

  //PIDO LOS DATOS AL BACKEND
  const loadUsers = async () => {

    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/users_all', {
        method: "POST"
      } )
      const data = await response.json()
      //console.log(data)
      SetUsuarios(data)
    } catch (error) {

      console.log(error)

    }

  }

   //recibe parametro para BORRAR la id seleccionada en el backend
   const handleDelete = async (id) => {
    const confirmed = window.confirm("¿Estás seguro de que deseas eliminar este usuario?");

    if (confirmed) {
      // Si el usuario confirma, se procede con la eliminación en el frontend
      SetUsuarios(usuarios.filter(usuario => usuario.username !== id));

      try {
        // Se elimina la categoría en el backend
        const resp = await fetch(process.env.REACT_APP_API_URL + `/usersDelete?id=${id}`, {
          method: "POST"
        });

        // Si la eliminación es exitosa, muestra la respuesta en consola
        console.log(resp);

      } catch (error) {
        // Si ocurre un error, lo muestra en consola
        console.log(error);
      }
    } else {
      // Si el usuario cancela, no se hace nada
      console.log("Eliminación cancelada");
    }
  };


  useEffect(() => {
    if (usuario.type === '') {
      navigate("/");
  }
    loadUsers()
  }, [])


  return (
    <>
      <h2>Listado de Usuarios con acceso al sistema:</h2>

      {

        (usuarios.length !== 0) ?


        usuarios.map(user => (
            <Card
              style={
                {
                  marginBottom: '.7rem',
                  backgroundColor: '#1e272e'
                }
              }
              key={user.username}
            >
              <CardContent style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}>
                <div style={{ color: 'white' }}>


                  <Typography variant='h6'>{'Nombre de usuario: ' + user.username}</Typography>
                  <Typography>{'Nombre y apellido: ' + user.nombre + ' ' + user.apellido}</Typography>
                  <Typography>{'Permisos: ' + user.type}</Typography>

                </div>
                <div>
   

                  {usuario.type === 'admin' ?
                    <Button
                      variant="contained"
                      color="warning"
                      //onClick={() => console.log('Borrando')}
                      onClick={() => handleDelete(user.username)}
                      style={{
                        marginLeft: '.5rem'
                      }}
                    >
                      Borrar (no funciona)
                    </Button>
                    :
                    null
                  }


                </div>
              </CardContent>
            </Card >
          )

          )

          :
          <Grid container spacing={3}>
            <Grid item xs={1}>

            </Grid>

            <Grid item xs={10} >
              <h4>No hay resultados para la búsqueda.</h4>
            </Grid>

            <Grid item xs={1} >

            </Grid>
          </Grid>

      }

    </>
  )
}
