import { useEffect, useState } from "react"
import { Button, Card, CardContent, Typography, Grid, Snackbar, Alert } from '@mui/material'
import { useNavigate } from 'react-router-dom';

export default function PathologiesSymptomsListPending({ usuario }) {

  const [relacionpatsym, SetRelacionPatSym] = useState([])

  const navigate = useNavigate();

  // Snackbar state
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  // Función para cerrar el Snackbar
  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };



  //PIDO LOS DATOS AL BACKEND
  const loadRelacionesPatSymPending = async () => {

    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/pat_symPending')
      const data = await response.json()
      // console.log(data)
      SetRelacionPatSym(data)
    } catch (error) {

      //console.log(error)
      console.log(error.message)
    }

  }

  //recibe parametro para VALIDAR LA RELACION en el backend
  const handleValidate = async (pat_id, sym_id) => {
    //e.preventDefault();

    const confirmed = window.confirm("¿Estás seguro de que deseas validar esta relación patología-síntoma?");
    if (!confirmed) {
      return; // Si el usuario cancela, no ejecuta el delete
    }

    SetRelacionPatSym(relacionpatsym.filter((relacion) => !(relacion.pat_id === pat_id && relacion.sym_id === sym_id)));

    try {
      //borra la id seleccionada en el back end
      const resp = await fetch(
        process.env.REACT_APP_API_URL + `/pat_symValidate?pat_id=${pat_id}&sym_id=${sym_id}`,
        {
          method: "POST",
          mode: "cors"
        }
      );

      console.log(resp);

      setSnackbar({
        open: true,
        message: 'Relación patología-síntoma validada exitosamente.',
        severity: 'success',
      });

    } catch (error) {
      console.log(error);
    }
  };



  //recibe parametro para BORRAR la id seleccionada en el backend
  const handleDelete = async (pat_id, sym_id) => {
    //e.preventDefault();

    const confirmed = window.confirm("¿Estás seguro de que deseas eliminar esta relación patología-síntoma?");
    if (!confirmed) {
      return; // Si el usuario cancela, no ejecuta el delete
    }

    SetRelacionPatSym(relacionpatsym.filter((relacion) => !(relacion.pat_id === pat_id && relacion.sym_id === sym_id)));

    try {
      //borra la id seleccionada en el back end
      const resp = await fetch(
        process.env.REACT_APP_API_URL + `/pat_symDelete?pat_id=${pat_id}&sym_id=${sym_id}`,
        {
          method: "POST",
          mode: "cors"
        }
      );

      setSnackbar({
        open: true,
        message: 'Relación patología-síntoma eliminada exitosamente.',
        severity: 'success',
      });
      //console.log(resp);
    } catch (error) {
      console.log(error);
      setSnackbar({
        open: true,
        message: 'Error al eliminar el síntoma.',
        severity: 'error',
      });
    }
  };



  useEffect(() => {
    if (usuario.type === '') {
      navigate("/");
    }
    loadRelacionesPatSymPending()

  }, [])


  return (
    <>
      <h2>Listado de Relaciones Patología-Síntoma pendientes de verificar del sistema</h2>

      {

        (relacionpatsym.length !== 0) ?

          relacionpatsym.map(relacion => (
            <Card
              style={
                {
                  marginBottom: '.7rem',
                  backgroundColor: '#bdbdbd'
                }
              }
              key={relacion.pat_name + relacion.sym_id}
            >
              <CardContent style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}>

                <Grid container spacing={2}>
                  <Grid item xs={6}>

                    <div style={{ color: 'black' }}>
                      <Typography variant='h6' >{'PAT: ' + relacion.pat_name}</Typography>
                      <Typography variant='h6' >{'SYM: ' + relacion.sym_name}</Typography>
                      <Typography>{'PAT Id: ' + relacion.pat_id}</Typography>
                      <Typography>{'SYM Id: ' + relacion.sym_id}</Typography>
                      <Typography>{'Código HPO/RDCom: ' + relacion.sym_hpo}</Typography>
                      <Typography>{'Link referencia: ' + relacion.link}</Typography>
                      <Typography>{'Importante: ' + relacion.important}</Typography>
                      <Typography>{'Frecuencia: ' + relacion.frequency}</Typography>
                      <Typography>{'Creada por: ' + relacion.username}</Typography>
                    </div>

                  </Grid>
                  <Grid item xs={6}>

                    <div>

                      <Button
                        variant="contained"
                        color="success"
                        //onClick={() => console.log('Editando')}
                        style={{
                          marginLeft: '.5rem',
                          marginBottom: '.5rem'
                        }} // Separación inferior
                        onClick={() => handleValidate(relacion.pat_id, relacion.sym_id)}
                      >
                        Validar Relación
                      </Button>

                      <Button
                        variant="contained"
                        color="inherit"
                        //onClick={() => console.log('Editando')}
                        style={{
                          marginLeft: '.5rem',
                          marginBottom: '.5rem'
                        }} // Separación inferior
                        onClick={() => navigate(`/pathologies_symptoms/${relacion.pat_id}/${relacion.sym_id}/edit`)}
                      >
                        Editar relación
                      </Button>

                      <Button
                        variant="contained"
                        color="inherit"
                        //onClick={() => console.log('Editando')}
                        style={{
                          marginLeft: '.5rem',
                          marginBottom: '.5rem'
                        }} // Separación inferior
                        onClick={() => navigate(`/symptoms/${relacion.sym_id}/edit`)}
                      >
                        Editar síntoma
                      </Button>

                      <Button
                        variant="contained"
                        color="inherit"
                        //onClick={() => console.log('Editando')}
                        onClick={() => navigate(`/categories_symptoms/${relacion.sym_id}/${encodeURIComponent(relacion.name)}`)}
                        style={{
                          marginLeft: '.5rem',
                          marginBottom: '.5rem'
                        }}
                      >
                        Categorías asociadas al síntoma
                      </Button>

                      <Button
                        variant="contained"
                        color="inherit"
                        //onClick={() => console.log('Editando')}
                        onClick={() => navigate(`/pathologies/fromonesymptom/${relacion.sym_id}`)}
                        style={{
                          marginLeft: '.5rem',
                          marginBottom: '.5rem'
                        }}
                      >
                        Patologías asociadas al síntoma
                      </Button>


                      <Button
                        variant="contained"
                        color="warning"
                        //onClick={() => console.log('Borrando')}
                        onClick={() => handleDelete(relacion.pat_id, relacion.sym_id)}
                        style={{
                          marginLeft: '.5rem',
                          marginBottom: '.5rem'
                        }}
                      >
                        Borrar relación
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card >
          )

          )

          :
          <Grid container spacing={3}>
            <Grid item xs={1} >

            </Grid>

            <Grid item xs={10} >
              <h4>No hay resultados para la búsqueda.</h4>
            </Grid>

            <Grid item xs={1} >

            </Grid>
          </Grid>

      }

      {/* Snackbar para notificaciones */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

    </>
  )
}
