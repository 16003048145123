
import { Grid, Card, Typography, CardContent, TextField, Button, CircularProgress } from '@mui/material';

import { useState, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

export default function PathologiesForm({ usuario }) {

  const [patologia, SetPatologia] = useState({
    pat_id: '',
    name: '',
    orpha_id: '',
    omim_id: ''

  });

  const [cargando, SetCargando] = useState(false);
  const [editando, SetEditando] = useState(false);
  const [tieneidiomas, SetTieneIdiomas] = useState(false);
  const navigate = useNavigate()
  const params = useParams()


  const [idiomas, SetIdiomas] = useState([
    {
      pat_id: params.id ? params.id : patologia.pat_id,
      language: "ES",
      value: "Ingrese traducción",
      state: "pending",
      username: usuario.username
    },
    {
      pat_id: params.id ? params.id : patologia.pat_id,
      language: "EN",
      value: "Ingrese traducción",
      state: "pending",
      username: usuario.username
    },
    {
      pat_id: params.id ? params.id : patologia.pat_id,
      language: "PO",
      value: "Ingrese traducción",
      state: "pending",
      username: usuario.username
    }
  ]);

  const camposIdiomaCompletados = () => {
    return idiomas.every(idioma => idioma.value !== "Ingrese traducción");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log('submit')
    //console.log(patologia)

    SetCargando(true)

    if (editando) {


      console.log('Editado')

      //HAGO EL POST Y RECIBO LA RESPUESTA EN RES PARA ACTUALIZAR UNA PATOLOGIA
      try {
        const res = await fetch(process.env.REACT_APP_API_URL + `/pathologiesUpdate?id=${params.id}`,
          {
            method: "POST",
            body: JSON.stringify(patologia),
            mode: "cors",
            headers: { "Content-Type": "application/json" }
          }
        )
        const data = await res.json()
        console.log(data)
      } catch (error) {

        console.log(error)

      }


    } else {
      try {
        //HAGO EL POST Y RECIBO LA RESPUESTA EN RES PARA CREAR UNA PATOLOGIA
        const res = await fetch(process.env.REACT_APP_API_URL + `/pathologiesAdd?username=${usuario.username}&state=pending`, {
          method: "POST",
          body: JSON.stringify(patologia),
          mode: "cors",
          headers: { "Content-Type": "application/json" }
        })

        // OBTENGO LOS DATOS REALES DE LA RESPUESTA EL POST ANTERIOR
        const data = await res.json()
        console.log(data)

      } catch (error) {

        console.log(error)

      }

    }

    //SI NO TENIA IDIOMAS LOS CREO AHORA!

    if (!tieneidiomas) {
      console.log("no tiene idiomas, hay que crearlos");

      for (const idioma of idiomas) {

        try {
          const res = await fetch(
            `${process.env.REACT_APP_API_URL}/pathologiestranslationscreateone?username=${usuario.username}`,
            {
              method: "POST",
              body: JSON.stringify(idioma),
              mode: "cors",
              headers: { "Content-Type": "application/json" },
            }
          );
          const data = await res.json();
          console.log(data); // Puedes manejar la respuesta aquí
        } catch (error) {

          console.log(error)

        }

      }

    }

    //TERMINO EL PROCESO 
    SetCargando(false)

    //LO ENVIO AL INICIO 
    navigate('/home/')

  }

  const handleOnChange = e => {
    //  console.log(e.target.name, e.target.value)
    SetPatologia({ ...patologia, [e.target.name]: e.target.value })
  }

  const handleSubmitIdioma = async (idioma) => {
    //  idioma.preventDefault();

    console.log("Actualizando idioma...")
    console.log(idioma)

    const confirmed = window.confirm("¿Estás seguro de que deseas actualizar el idioma de esta patologia?");
    if (!confirmed) {
      return; // Si el usuario cancela, no ejecuta el delete
    }
    try {
      const res = await fetch(process.env.REACT_APP_API_URL + `/pathologiestranslationsupdateone?username=${usuario.username}`, {
        method: "POST",
        body: JSON.stringify(idioma),
        mode: "cors",
        headers: { "Content-Type": "application/json" },
      });

    } catch (error) {

      console.log(error)

    }
  }

  const handleOnChangeidiomas = (e, index) => {
    const { name, value } = e.target;

    // Crear una copia de `idiomas` y actualizar el idioma específico
    const newIdiomas = [...idiomas];
    newIdiomas[index] = {
      ...newIdiomas[index],
      [name]: value
    };

    // Actualizar el estado con la copia modificada
    SetIdiomas(newIdiomas);
  };

  //// AGREGO CODIGO PARA CUANDO VENGO A EDITAR USANDO EL MISMO FORM DE CREAR

  const cargarPatologia = async (id) => {
    try {
      const res = await fetch(process.env.REACT_APP_API_URL + `/pathologiesOne?id=${id}`)
      const data = await res.json()
      console.log(data)
      SetPatologia({ pat_id: data[0].pat_id, name: data[0].name, orpha_id: data[0].orpha_id, omim_id: data[0].omim_id })
      SetEditando(true)
    } catch (error) {

      console.log(error)

    }

  }

  const zeroPad = (num, places) => String(num).padStart(places, '0')


  const cargarUltimaPatologia = async () => {
    try {
      const res = await fetch(process.env.REACT_APP_API_URL + `/pathologiesLast/`)
      const data = await res.json()

      console.log(data[0].max)
      let dato = data[0].max.split("_")
      console.log(dato[1])
      let numeroultimo = parseInt(dato[1]) + 1
      console.log(numeroultimo)
      numeroultimo = zeroPad(numeroultimo, 6)
      numeroultimo = 'PAT_' + numeroultimo
      TextField.pat_id = numeroultimo

      SetPatologia({ pat_id: numeroultimo })

      SetIdiomas([
        {
          pat_id: numeroultimo,
          language: "ES",
          value: "Ingrese traducción",
          state: "pending",
          username: usuario.username
        },
        {
          pat_id: numeroultimo,
          language: "EN",
          value: "Ingrese traducción",
          state: "pending",
          username: usuario.username
        },
        {
          pat_id: numeroultimo,
          language: "PO",
          value: "Ingrese traducción",
          state: "pending",
          username: usuario.username
        }
      ])
      console.log(idiomas)

    } catch (error) {

      console.log(error)

    }

  }


  //PIDO LOS DATOS AL BACKEND
  const loadIdiomas = async (id) => {

    try {
      const response = await fetch(process.env.REACT_APP_API_URL + `/pathologiestranslationsgetone?pat_id=${id}`)
      const data = await response.json()
      //console.log(data)

      // Solo actualiza el estado si `data` no está vacío
      if (data && data.length > 0) {
        SetIdiomas(data);
        console.log("Idiomas cargados:", data);
        SetTieneIdiomas(true);
      } else {
        console.log("La consulta de idiomas está vacía, no se actualiza el estado.");

      }

      console.log(data)
      console.log(idiomas)
    } catch (error) {

      console.log(error)

    }

  }

  useEffect(() => {
    if (usuario.type === '') {
      navigate("/");
    }
    //si viene con algun id..
    if (params.id) {
      console.log('viene con un dato de patologia para editar')
      cargarPatologia(params.id)
      loadIdiomas(params.id)
    }
    else {
      console.log('viene sin datos para crear una patologiaS')
      cargarUltimaPatologia();
    }

  }, [params.id])




  return (

    <Grid container direction={'column'} alignContent={'center'} justifyContent={'center'} >
      <Grid item xs={3}>
        <Card
          sx={{ mt: 5, width: 550 }}
          style={{
            backgroundColor: '#bdbdbd',
            padding: '1 rem'
          }}
        >
          <Typography variant='h6' textAlign='center' >
            {editando ? "Editar Patología" : "Crear Nueva Patología"}

          </Typography>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <TextField
                variant='filled'
                label='Nombre de la patología'
                name='name'
                value={patologia.name || ''}
                onChange={handleOnChange}
                sx={{ display: 'flex', margin: '.5rem 0' }}
                inputprops={{ style: { color: 'white' } }}
                inputlabelprops={{ style: { color: 'white' } }}
              />
              <TextField
                variant='standard'
                label='Código Patología (generado por el sistema)'
                name='pat_id'
                disabled={true}
                value={patologia.pat_id || ''}
                onChange={handleOnChange}
                sx={{ display: 'block', margin: '.5rem 0' }}
                inputprops={{ style: { color: 'white' } }}
                inputlabelprops={{ style: { color: 'white' } }}
              />
              <TextField
                variant='filled'
                label='Código Orpha'
                name='orpha_id'
                value={patologia.orpha_id || ''}
                onChange={handleOnChange}
                sx={{ display: 'block', margin: '.5rem 0' }}
                inputprops={{ style: { color: 'white' } }}
                inputlabelprops={{ style: { color: 'white' } }}
              />
              <TextField
                variant='filled'
                label='código Omim'
                name='omim_id'
                value={patologia.omim_id || ''}
                onChange={handleOnChange}
                sx={{ display: 'block', margin: '.5rem 0' }}
                inputprops={{ style: { color: 'white' } }}
                inputlabelprops={{ style: { color: 'white' } }}
              />

              <Button
                variant='contained'
                color='primary'
                type='submit'
                sx={{ display: "block", margin: ".5rem auto" }}
                disabled={!patologia.pat_id || !patologia.name || cargando || !camposIdiomaCompletados()}
              >
                {cargando ? < CircularProgress
                  color='inherit'
                  size={24}

                /> : 'Guardar'}

              </Button>

              {!camposIdiomaCompletados() && (
                <Typography variant="body2" color="error" textAlign="center" sx={{ marginTop: ".5rem" }}>
                  Por favor, complete todas las traducciones antes de guardar.
                </Typography>
              )}

            </form>

            <br></br>
            <Typography variant="h6" textAlign="center">
              Idiomas:
            </Typography>
            <br></br>

            {

              (idiomas.length !== 0) ?


                idiomas.map((idioma, index) => (
                  <Card
                    style={
                      {
                        marginBottom: '.3rem',
                        backgroundColor: '#bdbdbd'
                      }
                    }
                    key={idioma.language}
                  >
                    <CardContent style={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}>
                      <div style={{ color: 'white' }}>

                        <Grid container spacing={3}>
                          <Grid item xs={3}> {/* Ocupa menos espacio */}

                            <TextField
                              variant="filled"
                              label="Idioma"
                              name="language"
                              value={idioma.language}
                              disabled={true}
                              onChange={(e) => handleOnChangeidiomas(e, index)}
                              sx={{ display: "flex", margin: ".5rem 0" }}
                              inputprops={{ style: { color: "white" } }}
                              inputlabelprops={{ style: { color: "white" } }}
                            />
                          </Grid>

                          <Grid item xs={9}> {/* Ocupa más espacio */}
                            <TextField
                              variant="filled"
                              label="Traducción"
                              name="value"
                              value={idioma.value}
                              onChange={(e) => handleOnChangeidiomas(e, index)}
                              sx={{ display: "flex", margin: ".5rem 0", width: "100%" }}
                              inputprops={{ style: { color: "white" } }}
                              inputlabelprops={{ style: { color: "white" } }}
                            />
                          </Grid></Grid>

                        {(tieneidiomas) ?
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={() => handleSubmitIdioma(idioma)}
                            sx={{ display: "block", margin: ".5rem auto" }}
                            disabled={!idioma.language || !idioma.value}
                          >
                            {cargando ? (
                              <CircularProgress color="inherit" size={24} />
                            ) : (
                              "Actualizar idioma"
                            )}
                          </Button>
                          :
                          ''
                        }
                      </div>
                    </CardContent>
                  </Card >
                )

                )

                :
                <Grid container spacing={3}>
                  <Grid item xs={1}>

                  </Grid>

                  <Grid item xs={10} >
                    <h4>No se han cargado idiomas para este síntoma.</h4>


                  </Grid>

                  <Grid item xs={1} >

                  </Grid>
                </Grid>

            }






          </CardContent>

        </Card>
      </Grid>
    </Grid>
  )
}
